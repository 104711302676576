import tinymce from 'tinymce';
window.tinymce = tinymce;

// Icons
import 'tinymce/icons/default';

// Themes
import 'tinymce/themes/silver';

// Models
import 'tinymce/models/dom';

import 'tinymce/skins/ui/oxide/skin.js';

// Plugins
import 'tinymce/plugins/accordion';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/autosave';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/codesample';
import 'tinymce/plugins/directionality';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/importcss';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/pagebreak';
import 'tinymce/plugins/preview';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/save';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/visualblocks';
import 'tinymce/plugins/visualchars';
import 'tinymce/plugins/wordcount';

// tinyMCE.baseURL = "/libs/js/tinymce";
jQuery(document).ready(function() {
  tinymce.PluginManager.add('myMessages', function(editor) {
    if (ff3_configuration['permissions']['EmailMessageUse']) { // it's here, because we do not have to handle the plugin loading, we simply do nothing, when plugin should not be loaded
      editor.ui.registry.addMenuButton('myMessages', {
        text: 'Messages',
        fetch: (callback) => {
          let messages = JSON.parse(ff3_configuration['system/tinymce/messages']);
          let data = {};
          const items = messages.map(message => ({
            type: 'menuitem',
            text: message.text,
            onAction: () => {
              window.TinyMCE.removeBogusElements(editor);
              const idEmail = parseInt(message.value);
              if (!isNaN(idEmail) && idEmail > 0) {
                data = { id: idEmail };
                if (editor.settings && editor.settings.myMessages_ids) {
                  for (let key in editor.settings.myMessages_ids) {
                    data[key] = jQuery(editor.settings.myMessages_ids[key]).val();
                  }
                }
              }

              jQuery.ajax({
                url: '/comm/EmailMessage/ajaxGetEmailMessageBody',
                type: 'POST',
                data: data,
                success: function(response) {
                  editor.execCommand('mceInsertContent', false, response);
                  editor.focus();
                },
                error: function() {
                  Notifications.showErrorMessage('Loading failed.');
                },
              });
            },
          }));
          callback(items);
        },
      });
    }
  });

  tinymce.PluginManager.add('signature', function(editor) {
    if (ff3_configuration['permissions']['EmailMessageUse']) {
      editor.ui.registry.addButton('signature', {
        'text': 'Signature',
        'tooltip': 'Insert Signature',
        onAction: function() {
          window.TinyMCE.removeBogusElements(editor);
          jQuery.ajax({
            url: '/comm/EmailMessage/ajaxGetSignature',
            type: 'POST',
            success: function(response) {
              editor.insertContent(response);
              editor.focus();
            },
            error: function() {
              Notifications.showErrorMessage('Loading failed.');
            },
          });
        },
      });
    }
  });
});

window.TinyMCE = {
  tinyMCEInitList: [],

  removeBogusElements: function(editor) {
    editor.getBody().querySelectorAll('[data-mce-bogus]').forEach(el => {
      el.replaceWith(document.createElement('br'));
    });
  },

  tinymceRemove: function(elementId) {
    if (window.TinyMCE.tinymce_already_initialized(elementId)) {
      let editor = window.TinyMCE.tinyMCEInitList[elementId];
      editor.remove();
      delete window.TinyMCE.tinyMCEInitList[elementId];
    }
  },

  tinymce_oninit: function(ed) {
    //    ed.controlManager.setActive('spellchecker', true);
    //    ed.execCommand('mceSpellCheck', true);
    window.TinyMCE.tinyMCEInitList[ed.id] = ed;
  },

  // Checks, if given tinymce is already initialized.
  // Currently only the ID selectors are supported. This functionality rely on tinymce_oninit() and tinymce_oninit_colorbox().
  tinymce_already_initialized: function(selector) {
    return window.TinyMCE.tinyMCEInitList && window.TinyMCE.tinyMCEInitList[selector];
  },

  tinymce_oninit_colorbox: function(editor) {
    window.TinyMCE.tinyMCEInitList[editor.id] = editor;
    setTimeout(function(){
      let cbox = ColorboxManager.getEnclosing(editor.editorContainer);
      if (cbox) cbox.resize();
    }, 50);
  },

  /**
   * Manages the upload of the image and conversion to data URI.
   *
   * @param callback
   */
  tinymce_file_picker_callback: function(callback) {
    let input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.onchange = function() {
      let file = this.files[0];
      let reader = new FileReader();
      reader.onload = function() {
        callback(reader.result, { title: file.name });
      };
      reader.readAsDataURL(file);
    };

    input.click();
  },

  initTinyMCECommonOptions: function(extendedOptions) {
    // concatenate setup instead of overriding
    if(typeof extendedOptions.setup === 'function') {
      var setup = extendedOptions.setup;
      delete extendedOptions.setup;
    }

    window.TinyMCE.get_custom_colors(function(colorMap) {
      let options = {
        cache_suffix: '?v=' + window.tinyMCE.majorVersion + '.' + window.tinyMCE.minorVersion,
        invalid_elements: ff3_configuration['system/tinymce/invalid-elements'],
        entity_encoding: 'raw',
        browser_spellcheck: true,
        resize: 'both',
        license_key: 'gpl',
        toolbar_mode: 'wrap',
        elementpath: false,
        skin_url: '/libs/js/tinymce/skins/light_fortix',
        content_css: '/images/system/css/tinymce.css?v='+ff3_configuration['system/tinymce/css-style-hash'],
        remove_script_host : false,
        relative_urls : false,
        convert_urls : false,
        promotion: false,
        min_height: 400,
        paste_use_dialog : false,
        paste_auto_cleanup_on_paste : true,
        paste_convert_headers_to_strong : false,
        paste_strip_class_attributes : 'all',
        paste_remove_spans : true,
        paste_remove_styles : true,
        paste_data_images: true,
        force_br_newlines : true,
        moxiemanager_title: 'File Manager',
        color_map: colorMap,
        setup: (editor) => {
          editor.on('init', () => {
            if (extendedOptions.init_instance_callback) {
              if (typeof extendedOptions.init_instance_callback == 'string') {
                if (window.TinyMCE[extendedOptions.init_instance_callback]) {
                  window.TinyMCE[extendedOptions.init_instance_callback](editor);
                }
              }
              else if (typeof extendedOptions.init_instance_callback == 'function') {
                extendedOptions.init_instance_callback(editor);
              }
              delete extendedOptions.init_instance_callback;
            }
            window.TinyMCE.removeBogusElements(editor);
          });
          editor.on('change', () => {
            window.TinyMCE.removeBogusElements(editor);
          });
          editor.on('setcontent', () => {
            window.TinyMCE.removeBogusElements(editor);
          });
          editor.on('BeforeSetContent', function(e) {
            const emailInBracketsRegex = /<([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})>/g;
            const text = e.content;

            // Replace only the angle brackets around the email address with HTML entities.
            e.content = text.replace(emailInBracketsRegex, (_, email) => {
              return `&lt;${email}&gt;`;
            });
          });

          if(setup) {
            setup(editor);
          }
        },
        paste_preprocess: (editor, args) => {
          editor = tinymce.get(editor.id);
          const tree = jQuery('<div>' + args.content + '</div>');

          tree.find('img[src^="file://"]').remove();
          let content = tree.html() || '';
          content = content.replace(/<!\[[^>\]]+\]>/g, '');
          content = content.replace(/<\/?o:[^>]+>/g, '');
          args.content = content;
        },
        content_style: 'h1 {font-size: 25px;font-weight: 400;}' +
          'h2 {font-size: 22px;font-weight: 400; margin-bottom: 8px; margin-top: 0;}\n' +
          'h3 {font-size: 20px;font-weight: 400; margin-bottom: 8px; margin-top: 0;}\n' +
          'h4 {font-size: 18px;font-weight: 400; margin-bottom: 8px; margin-top: 0;}\n' +
          'h5 {font-size: 16px;font-weight: 400; margin-bottom: 8px; margin-top: 0;}' +
          'h6 {font-size: 14px;font-weight: 400; margin-bottom: 8px; margin-top: 0;}',
      };

      if (extendedOptions) {
        options = jQuery.extend(options, extendedOptions);
      }

      // convert string to function - yes, the init_instance_callback does not need this...
      if (options.file_picker_callback) {
        options.file_picker_callback = window[options.file_picker_callback];
      }
      tinymce.init(options);
    });
  },

  initTinyMCEEditor_Minimal: function(selector, optionOverrides, readonly = false) {
    if (Number.isInteger(readonly)) {
      Boolean(readonly);
    }

    var options = {
      selector: selector,
      plugins : 'code,autoresize',
      toolbar: 'bold italic underline strikethrough forecolor backcolor | alignleft aligncenter alignright alignfull | undo redo | bullist numlist link code',
      menubar: false,
      readonly: readonly,
    };

    if (optionOverrides) {
      options = jQuery.extend(options, optionOverrides);
    }

    window.TinyMCE.initTinyMCECommonOptions(options);
  },

  initTinyMCEEditorFull: function(elementId, profile_name, additionalPlugins, additionalOptions, readonly = false) {
    if (Number.isInteger(readonly)) {
      Boolean(readonly);
    }

    var options = {
      selector: '#' + elementId,
      plugins : 'pagebreak,table,save,image,link,emoticons,insertdatetime,media,searchreplace,directionality,fullscreen,visualchars,nonbreaking,code,lists,signature,myMessages,autoresize',
      toolbar: 'undo redo | styles fontfamily fontsize forecolor backcolor | bold italic underline strikethrough subscript superscript | alignleft aligncenter alignright alignjustify | bullist numlist | link image code | signature | myMessages',
      readonly: readonly,
      fontsize_formats : '6pt 7pt 8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
      font_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
      extended_valid_elements : 'pre[id|class|name],textarea[cols|rows|disabled|name|readonly|class]',
      myMessages_ids : { id_request: '#reply_form input[name="id"]' },
    };

    if (additionalPlugins && additionalPlugins != undefined && additionalPlugins != '') {
      options.plugins += ','+additionalPlugins;
    }

    if (additionalOptions && additionalOptions.toolbar != undefined && additionalOptions.toolbar != '') {
      options.toolbar += ' '+additionalOptions.toolbar;
      delete additionalOptions.toolbar;
    }

    if (additionalOptions != undefined && typeof additionalOptions === 'object') {
      jQuery.extend(options, additionalOptions);
    }

    if (profile_name) {
      // options.plugins += ',moxiemanager';
      options.moxiemanager_custom_data = 'fortix_profile:' + profile_name;
    }

    window.TinyMCE.initTinyMCECommonOptions(options);
  },

  add_content_to_editor: function(content) { // Add content to editor
    window.tinyMCE.activeEditor.insertContent(content);
  },

  TinyMCE_open_standalone_filemanager: function(profile_name) {
    window.moxman.browse({ 'title': 'File Manager', 'customData' : 'customData:fortix_profile:' + profile_name + '/api' });
  },

  // maybe not 100% but for now it does it's job - turns the editor area to read only and grays out the controls
  tinymce_disable: function(id) {
    let editor = tinymce.get(id);
    if (editor) {
      editor.mode.set('readonly');
      let editorContainer = jQuery(editor.editorContainer);
      editorContainer.find('button[type=button]').prop('disabled', true);
      editorContainer.find('a[role=presentation]').css('opacity','0.5');
      jQuery('#' + id + '_ifr').css('opacity','0.5');
    }
  },

  tinymce_enable: function(id) {
    let editor = tinymce.get(id);
    if (editor) {
      editor.mode.set('design');
      let editorContainer = jQuery(editor.editorContainer);
      editorContainer.find('button[type=button]').prop('disabled', false);
      editorContainer.find('a[role=presentation]').css('opacity','1');
      jQuery('#' + id + '_ifr').css('opacity', '1');
    }
  },

  get_custom_colors: function(callback) {
    // TinyMCE default colors
    let color_map = [
      '#BFEDD2', 'Light Green',
      '#FBEEB8', 'Light Yellow',
      '#F8CAC6', 'Light Red',
      '#ECCAFA', 'Light Purple',
      '#C2E0F4', 'Light Blue',

      '#2DC26B', 'Green',
      '#F1C40F', 'Yellow',
      '#E03E2D', 'Red',
      '#B96AD9', 'Purple',
      '#3598DB', 'Blue',

      '#169179', 'Dark Turquoise',
      '#E67E23', 'Orange',
      '#BA372A', 'Dark Red',
      '#843FA1', 'Dark Purple',
      '#236FA1', 'Dark Blue',

      '#ECF0F1', 'Light Gray',
      '#CED4D9', 'Medium Gray',
      '#95A5A6', 'Gray',
      '#7E8C8D', 'Dark Gray',
      '#34495E', 'Navy Blue',

      '#000000', 'Black',
      '#ffffff', 'White',
    ];

    // get custom colors defined by users
    jQuery.getJSON('/f1/TinyMCE/getCustomColors', {}, function(data) {
      if (data !== null) {
        color_map = color_map.concat(data);
      }
      callback(color_map);
    });
  },
};