document.addEventListener('alpine:init', () => {
  Alpine.data('recordedTime', (graphWidth, graphHeight, hoursPerDay, today, week, holiday) => ({
    today: '0 h 0 min',
    thisWeek: '0 h 0 min',
    holiday: holiday,
    leaveTotal: '0 d 0 h',
    graphWidth: graphWidth,
    graphHeight: graphHeight,
    hoursPerDay: hoursPerDay,
    time: today,
    week: week,

    updateGraph: function() {
      let time = (this.time / 3600).toFixed(2);
      window.google.setOnLoadCallback(window.drawTimeStatistics('time_statistics_graph', '', time, this.graphWidth, this.graphHeight, this.hoursPerDay));
    },

    updateTime: async function() {
      let resp = await fetch('/dashboard/RecordedTimeStatisticsDC/getRecordedTime/');
      let json = await resp.json();

      this.time = json.today;
      this.today = this.formatTime(json.today);
      this.thisWeek = this.formatTime(json.week);

      this.holiday = json.holiday;
      this.leaveTotal = this.formatDay(json.leaveTotal);

      this.updateGraph();
    },

    formatTime: function(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds / 60) % 60);

      return hours + ' h ' + minutes + ' min';
    },

    formatDay: function(seconds) {
      const days = Math.floor (seconds / 8);
      const hours = Math.floor(seconds % 8);

      return days + ' d ' + hours + ' h';
    },

    init: function() {
      if (!window.ff3_configuration.idUser) {
        return;
      }

      this.today = this.formatTime(this.time);
      this.thisWeek = this.formatTime(this.week);

      window.google.load('visualization', '1', { packages: [ 'corechart' ], callback: function() {
        this.updateGraph();
      }.bind(this) });

      setInterval(function() {
        this.updateTime();
      }.bind(this), 15000);
    },

  }));
});