window.unifiedHelpSearchComponent = function() {
  // Get the panel ID, we use this to make the persist keys unique & know which panel the show article event is for
  const panelId = this.$el.getAttribute('data-unified-help-panel-id');

  return {
    panelId: panelId,
    showResults: this.$persist(true).as('_x_search_show_results_' + panelId).using(sessionStorage),
    searching: true,
    searchData: {
      search_q: this.$persist('').as('_x_search_search_q_' + panelId).using(sessionStorage),
      q: this.$persist('').as('_x_search_q_' + panelId).using(sessionStorage),
      altTerm: '',
    },
    search: {
      results: [],
    },
    history: this.$persist([]).as('_x_search_search_history_' + panelId).using(sessionStorage),
    isPinned: this.$persist(false).as('_x_search_is_pinned_' + panelId).using(sessionStorage),
    showMenu: false,

    async init() {

      if(this.panelId == 1 && window.location.hash.match(/^#help_\d+[CFcf]$/)) {
        this.showResults = false;
      }
      else {
        if(!this.search.results.length || !this.isPinned || this.showResults) {
          this.searchForCurrentPage();
        } else {
          this.searching = false;
        }
      }
    },
    async runSearch() {
      this.searchData.altTerm = '';
      this.searching = this.showResults = true;
      this.searchData.q = this.searchData.search_q;
      this.updateResults();
    },
    async togglePin() {
      if(!this.isPinned) {
        this.childPinned++;
        this.isPinned = true;
      } else {
        this.childPinned = Math.max(0, this.childPinned - 1);
        this.isPinned = false;
      }
    },
    async articleSinglePanel() {
      // For the article to be unpinned
      if(this.isPinned) {
        this.togglePin();
      }
    },
    async showByRefEvent() {
      if(this.$event.detail.inPanel == this.panelId) {
        var contentId = parseInt(this.$event.detail.ref.slice(0, this.$event.detail.ref.length - 1)),
          isGlobal = this.$event.detail.ref.slice(this.$event.detail.ref.length - 1).toLowerCase() == 'f';

        this.showArticle(contentId, isGlobal, this.$event.detail.inPanel);
      }
    },
    async showByTermEvent() {
      if(this.$event.detail.inPanel == this.panelId) {
        this.searching = this.showResults = true;
        this.searchData.q = this.searchData.search_q = this.$event.detail.term;
        this.searchData.altTerm = this.$event.detail.altTerm;
        this.updateResults();
      }
    },
    async showHelpHelp() {
      this.searchData.q = this.searchData.search_q = '';
      this.showArticle(202, true, this.panelId);
    },
    async searchForCurrentPage() {
      this.searching = true;
      this.searchData.q = this.searchData.search_q = '';
      this.search = { results: [] };

      var self = this;
      var uri = '/freedom3/UnifiedHelp/ajaxFetchHelp';
      var d = {
        uri: unifiedHelp.getURI(false),
        app_id: sessionStorage.getItem('_unifiedHelp_appId'),
      };

      // Load help content
      jQuery.ajax(
        uri,
        {
          method: 'post',
          dataType: 'json',
          async: true,
          data: d,

          success: function(data) {

            // If only 1 article then show it
            if(data.totalFound == 1) {
              self.showArticle(data.content.contentId ? data.content.contentId : data.content.globalContentId, data.content.isGlobal, self.panelId);
            }
            else {
              self.search = data;
              self.searching = false;
            }
          },
        },
      );
    },
    async updateResults() {
      var self = this;

      if(this.searchData.q.length > 0) {
        jQuery.ajax(
          '/freedom3/UnifiedHelp/ajaxSearch',
          {
            method: 'post',
            dataType: 'json',
            async: true,
            data: {
              q: this.searchData.q,
              app_id: sessionStorage.getItem('_unifiedHelp_appId'),
            },
            success: function(data) {
              self.searching = false;

              // If not just 1 result the remember the search term
              if(data.totalFound > 1) {

                // If last item not the same as this then add it
                try {
                  if(!self.history.length || self.history[self.history.length - 1].searchData.search_q != self.searchData.search_q) {
                    self.history.push({ searchData: { ...self.searchData } });
                  }
                } catch {
                  self.history = [];
                  self.history.push({ searchData: { ...self.searchData } });
                }

                // If history longer than 25 items then remove the first
                if(self.history.length > 25) {
                  self.history.shift();
                }
              }

              // If no results and alt term then try that
              if(!data.totalFound && self.searchData.altTerm.length) {
                self.searchData.q = self.searchData.search_q = self.searchData.altTerm;
                self.searchData.altTerm = '';
                self.updateResults();
              }
              else {
                jQuery('#unified-help-q').attr('data-alt-term', '');
                self.search = data;

                // If only 1 article then load it
                if(data.totalFound == 1) {
                  self.showArticle(data.results[0].contentId, data.results[0].isGlobal, self.panelId);
                }
              }
            },
          },
        );
      }
      else {
        this.searchForCurrentPage();
      }
    },
    async goBack() {
      if(this.history.length > 1) {
        this.searching = true;
        this.history.pop();
        var last = this.history[this.history.length - 1];
        this.searchData.q = last.searchData.q;
        this.searchData.search_q = last.searchData.search_q;
        this.updateResults();
      }
    },
    async showArticle(contentId, isGlobal, inPanel) {
      this.$dispatch('unifiedhelp-article', { contentId: contentId, isGlobal: isGlobal, inPanel: inPanel });
      this.showResults = false;
    },
    async createNewArticle() {
      this.$dispatch('unifiedhelp-create-article', { inPanel: 1 });
      this.showResults = false;
    },
    async showFortixHelp() {
      FortixSupport.show();
      window.toggle_menu_box('menu_user_info');
    },
    async articleDeletedEvent() {
      var contentId = this.$event.detail.contentId,
        isGlobal = this.$event.detail.isGlobal;

      // Remove the article from the search results if it's there
      this.search.results = this.search.results.filter(function(obj) {
        return obj.contentId !== contentId || obj.isGlobal !== isGlobal;
      });
    },
    async articleEditedEvent() {
      var contentId = this.$event.detail.contentId,
        isGlobal = this.$event.detail.isGlobal,
        title = this.$event.detail.title;

      // If the article is in the search results then update it
      for(var i = 0; i < this.search.results.length; i++) {
        if(this.search.results[i].contentId == contentId && this.search.results[i].isGlobal == isGlobal) {
          this.search.results[i].title = title;
          break;
        }
      }
    },
  };
};