document.addEventListener('alpine:init', () => {
  Alpine.data('projectPriorityReport', () => ({
    projects: [],
    saving: false,

    init: function() {
      if (!window.ff3_configuration.idUser) {
        return;
      }
    },

    save: function(e) {
      e.preventDefault();

      this.saving = true;

      let data = [];
      jQuery('[data-id-project]').each(function() {
        let projectId = jQuery(this).attr('data-id-project');
        let projectRequests = [];

        jQuery(this).find('[data-id-request]').each(function() {
          projectRequests.push(jQuery(this).attr('data-id-request'));
        });
        data.push({
          'id_project': projectId,
          'requests': projectRequests,
        });
      });

      fetch('/freedom3/ProjectPriorityReport/ajaxChangeProjectOrder', {
        method: 'POST',
        body: JSON.stringify({ 'data': data }),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.text();
        })
        .then(response => {
          ajax_loading_stop('wpr_ajax_loading_circle');
          jQuery('.work_priority_report_shade').css('display', 'none');
          jQuery('.work_priority_report_filter_shade').css('display', 'none');
          if ('Project Order OK' == response) {
            Notifications.showSuccessMessage('Project order has changed successfully!');
          }
          else if ('Access denied' == response) {
            Notifications.showErrorMessage('You are not authorized to change project order!');
          }
          else {
            Notifications.showErrorMessage('Project order couldn\'t be changed!');
          }

          this.saving = false;
        })
        .catch(() => {
          ajax_loading_stop('wpr_ajax_loading_circle');
          jQuery('.work_priority_report_shade').css('display', 'none');
          jQuery('.work_priority_report_filter_shade').css('display', 'none');
          Notifications.showErrorMessage('Project order couldn\'t be changed!');

          this.saving = false;
        });

      return false;
    },
  }));
});